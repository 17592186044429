// Right-aligned image which shows "reasonably large" but leaves some space to the left for text at all media sizes
.right-image
{
    float: right;
    clear: right;
    min-width: 30%;
    margin: 0.3rem;
}

// 49rem = same cutoff as the theme's cutoff for always visible sidebar
@media (min-width: 30rem) and (max-width: 49rem) {
    .right-image {
	max-width: 50%;
    }
}

@media (min-width: 49rem) and (max-width: 70rem) {
    .right-image {
	max-width: 100%;
    }
}

@media (min-width: 70rem) {
    .right-image {
	max-width: 50%;
    }
}

.small-image
{
    margin-left: auto;
    margin-right: auto;
    max-height: 20rem;
}

.small-left-image
{
    float: left;
    clear: left;
    min-width: 5rem;
    max-width: 5rem;
    margin: 0.3rem;
}

.small-right-image
{
    float: right;
    clear: right;
    min-width: 5rem;
    max-width: 5rem;
    margin: 0.3rem;
}

.label-icon
{
    float: left;
    clear: none;
    min-height: 5rem;
    max-height: 5rem;
    margin: 0.3rem;
}

.clear
{
    clear: both;
}

